import React from 'react';
import {GatsbyImage} from "gatsby-plugin-image";

const RoundedImage = ({ image, maxH, maxW, height, size, width, square, borderColor, gatsby, presseImage = false,  smallerBorder = false, smallestBorder = false, wide = false }) => {

    const src = image?.image?.asset?.gatsbyImageData;

    let srcWidth = '1024';
    let srcHeight = '1024';

    switch(size){
        case 'large':
            srcWidth = '4096';srcHeight = '4096';
            break;
        case 'small':
            srcWidth = '1024';srcHeight = '1024';
            break;
        case 'thumbnail':
            srcWidth = '512';srcHeight = '512';
            break;
        default:
            srcWidth = '1024';srcHeight = '1024';
    }

    const getRandomRotation = () => {
        return Math.random() * 2 - 1;
    }

    if (image){
        return (
            <>
            <div className={`relative ${presseImage || smallestBorder ? "border-[15px] rounded-[10px] " : smallerBorder ? "border-[20px] rounded-[15px] " : "border-[20px] tablet:border-[30px] rounded-[20px] "} w-full h-full`} style={{maxHeight: maxH ? maxH : 'initial', maxWidth: maxW ? maxW : 'initial', height: height ? height : 'initial', width: width ? width : 'initial', borderColor: borderColor, transform: 'rotate(' + getRandomRotation() + 'deg)'}}>
                <GatsbyImage
                    image={gatsby ? gatsby : src}
                    width={srcWidth}
                    height={srcHeight}
                    className={`w-full h-full overflow-hidden flex justify-center items-center ${maxH ? 'max-h-[calc(70vh-60px)] xlarge:max-h-[calc(70vh-60px)]' : ''} ${square ? 'aspect-square' : ''} ${wide ? 'aspect-square' : ''}`}
                    objectFit={"cover"}
                    objectPosition={"center"}
                    alt={image?.alt ? image?.alt : 'spleen'}
                />
                {!presseImage && !smallestBorder &&
                <div className={`absolute right-0  ${smallerBorder ? '-bottom-[20px] text-creditsSmall' : '-bottom-[18px] tablet:-bottom-[25px] text-creditsSmall tablet:text-credits'}`}>
                    {image?.credits}
                </div>}
            </div>
                {presseImage && image?.alt && <div className={"mt-1 ml-2 font-bold"}>{image?.alt}</div>}
                {presseImage && image?.credits && <div className={"ml-2"}>{image?.credits}</div>}
            </>
        );
    }
};

export default RoundedImage;
