import React from "react";
import intersperseWithLogo from "../../../util/intersperseWithLogo";
import {Link} from "gatsby";
import {localeLink} from "../../../util";

const ColoredText = ({text, type = 'h3', color = 'white', textColor = 'black', rotation = 'random', link, logoReplace = true, location, onClick, lowercase = false}) => {
    let fontSize = '2rem';

    switch(type){
        case 'h1':
            fontSize = '3rem'
            break
        case 'h2':
            fontSize = '2.5rem'
            break
        case 'h3':
            fontSize = '2rem'
            break
        case 'h4':
            fontSize = '1.5rem'
            break;
        case 'p':
            fontSize = '1.2rem'
            break;
    }

    let styledColor = 'white';
    switch(color){
        case 'white':
            styledColor = 'white'
            break
        case 'blue':
            styledColor = '#51CFEA'
            break
        case 'lightBlue':
            styledColor = '#EFF4F5'
            break
        case 'red':
            styledColor = '#FF6043'
            break
        case 'green':
            styledColor = '#A2CF44'
            break;
        default:
            styledColor = color;
    }
    let styledTextColor = 'black';
    switch(textColor){
        case 'black':
            styledTextColor = 'black'
            break
        case 'white':
            styledTextColor = 'white'
            break
        case 'blue':
            styledTextColor = '#61C3D9'
            break
        case 'lightBlue':
            styledTextColor = '#EFF4F5'
            break
        case 'red':
            styledTextColor = '#EA5D40'
            break
        case 'green':
            styledTextColor = '#AFCA0A'
            break;
        case 'grasGreen':
            styledTextColor = '#A2CF44'
            break
        default:
            styledTextColor = textColor;
    }

        const getRandomRotation = () => {
            return Math.random() * 2 - 1;

        }

    return (
        <div className={"relative inline-block pt-2 pb-2 px-2 z-20"}>
            <div className={`w-full h-full absolute top-0 left-0 z-0 origin-center transition-all ease duration-300`} style={{backgroundColor: styledColor, transform: rotation === 'random' ? 'rotate(' + getRandomRotation() + 'deg)' : 'rotate(' + rotation + 'deg)'}}></div>
            {link ?
                <Link onClick={onClick} to={localeLink(link, location)} className={`victor text-${type} z-10 relative ${lowercase && '!capitalize'}`} style={{fontSize: fontSize, color: styledTextColor}}>{logoReplace ? intersperseWithLogo(text, type, styledTextColor) : text}</Link>
                :
            <div className={`victor text-${type} z-10 relative ${lowercase && '!normal-case'}`} style={{fontSize: fontSize, color: styledTextColor}}>{logoReplace ? intersperseWithLogo(text, type, styledTextColor) : text}</div>
            }
            </div>
    )
}


export default ColoredText
