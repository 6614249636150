import { PortableText } from '@portabletext/react';
import React from 'react';
import ColoredText from "../elements/text/ColoredText";
import RoundedImage from "../elements/RoundedImage";
import sanityConfig from '../../../sanity-config'
import {Link} from "gatsby";
import {getSanityImageData} from "../../util/getSanityImageData";
import { getImage, getImageDimensions } from '@sanity/asset-utils';
import MyPortableText from "./MyPortableText";
import getYouTubeId from 'get-youtube-id'
import YouTube from "react-youtube";
import Logo from "../../util/Logo";
import Vimeo from "@u-wave/react-vimeo";

const intersperseLogoInText = (children, tagType, color, height, margin) => {
    return children.flatMap((child, index) => {
        if (typeof child === 'string') {
            const parts = child.split('spleen*');
            const newChildren = parts.flatMap((part, index) => [
                part,
                index < parts.length - 1 ? <Logo key={index} tagType={tagType} color={color} height={height} margin={margin} /> : null
            ]).filter(part => part !== null); // Filter out any nulls (end of array)
            return newChildren;
        } else {
            return child;
        }
    });
};

const textComponents = {
    block: {
        normal: ({ children, ...props }) => {
            const updatedChildren = intersperseLogoInText(children, 'p', '#000000', '23px', '3px');
            return <div className={"text-p aller max-w-text mt-5 first-of-type:mt-0"} {...props}>{updatedChildren}</div>;
        },
        einzug: ({ children, ...props }) => {
            const updatedChildren = intersperseLogoInText(children, 'p', '#000000', '20px', '3px');
            return <div className={"aller max-w-text mt-2 ml-5"} {...props}>{updatedChildren}</div>;
        },
        small: ({ children, ...props }) => {
            const updatedChildren = intersperseLogoInText(children, 'p', '#000000', '20px', '3px');
            return <div className={"aller max-w-text mt-2 first-of-type:mt-0"} {...props}>{updatedChildren}</div>;
        },
        h2: ({ children, ...props }) => {
            const updatedChildren = intersperseLogoInText(children, 'h2', '#000000', '40px', '3px');
            return <div className={"text-h2 victor max-w-text mt-10"} {...props}>{updatedChildren}</div>;
        },
        h3: ({ children, ...props }) => {
            const updatedChildren = intersperseLogoInText(children, 'h4', '#000000', '27px', '3px');
            return <div className={"text-h4 victor max-w-text mt-10"} {...props}>{updatedChildren}</div>;
        },
        h3colored: ({ children, ...props }) => {
            const getRandomRotation = () => {
                return Math.random() * 2 - 1;

            }
            const updatedChildren = intersperseLogoInText(children, 'h3', '#000000', '33px', '3px');
            return <div className={"relative inline-block pt-2 pb-2 px-2 z-20 mt-5"}>
                <div className={`w-full h-full absolute top-0 left-0 z-0 origin-center bg-white`} style={{transform: 'rotate(' + getRandomRotation() + 'deg)'}}></div>
                <div className={`victor text-h3 z-10 relative text-black`}>{updatedChildren}</div>
            </div>
        },
        h4: ({ children, ...props }) => {
            const updatedChildren = intersperseLogoInText(children, 'h4', '#000000', '40px', '3px');
            return <div className={"text-h4 victor max-w-text mt-10"} {...props}>{updatedChildren}</div>;
        },
        h4colored: ({ children, ...props }) => {
            const getRandomRotation = () => {
                return Math.random() * 2 - 1;

            }
            const updatedChildren = intersperseLogoInText(children, 'h4', '#000000', '25px', '3px');
            return <div className={"relative inline-block pt-2 pb-2 px-2 z-20 mt-5"}>
                <div className={`w-full h-full absolute top-0 left-0 z-0 origin-center bg-white`} style={{transform: 'rotate(' + getRandomRotation() + 'deg)'}}></div>
                <div className={`victor text-h4 z-10 relative text-black`}>{updatedChildren}</div>
            </div>
        },
    },
    marks: {
        strong: ({children}) => <span className={"font-bold"}>{children}</span>,
        link: ({value, children}) => {
            const target = (value?.href || '').startsWith('http') ? '_blank' : undefined
            return (
                <a href={value?.href} target={target} className="underline">
                    {children}
                </a>
            )
        },
        internalLink: ({value, children}) => {
            return (
                <Link to={value?.fixedpage} className="underline inline-block hover:-translate-y-0.5 duration-500 ease-in-out cursor-pointer">
                    {children}
                </Link>
            )
        },
    },
    types: {
        highlight_link: ({value}) => {
            const text = value.text;
            const link = value.link;
            return <div className="is-animated mt-5">
                <div className={"flex justify-center mt-5"}>
                    <a href={value.link} target={"_blank"} className={"hover:-translate-y-1 transition-all duration-300 ease-in-out"}>
                        <ColoredText color={'white'} text={value.text} type={"h3"}></ColoredText>
                    </a>
                </div>
            </div>
        },
            imageAlt: ({value}) => {
                const imageData = getImage(value.image.asset, sanityConfig).asset;
                const {width, height} = getImageDimensions(value.image);

                const image = {
                    url: imageData.url,
                    width,
                    height,
                };

                const gatsbyImageData = getSanityImageData({
                    image,
                    layout: 'constrained',
                });

                    return (
                        <div className="mt-10 is-animated">
                            <RoundedImage gatsby={gatsbyImageData} image={value} height={"400px"} width={"auto"} maxW={"700px"}></RoundedImage>
                        </div>
                    );
            },
        imageSection: ({value}) => {
            console.log(value);
            const imageData = getImage(value.image.asset, sanityConfig).asset;
            const {width, height} = getImageDimensions(value.image);

            const image = {
                url: imageData.url,
                width,
                height,
            };

            const gatsbyImageData = getSanityImageData({
                image,
                layout: 'constrained',
            });

            return (
                <div className="mt-10 is-animated flex justify-between max-w-[700px]">
                    <div className="w-1/2 pr-5">
                        <ColoredText text={value.headline} type={"h3"}></ColoredText>
                        <div className="mt-5">
                            <MyPortableText value={value.text}></MyPortableText>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <RoundedImage gatsby={gatsbyImageData} image={value} height={"400px"} width={"auto"} maxW={"100%"}></RoundedImage>
                    </div>
                </div>
            );
        },
        youtube: ({value}) => {
            const { url } = value
            const id = getYouTubeId(url)
            const getRandomRotation = () => {
                return Math.random() * 2 - 1;

            }
            return (
                <div className="is-animated">
                    <div className={`relative rounded-[20px] border-[30px] w-full h-fit`} style={{transform: 'rotate(' + getRandomRotation() + 'deg)'}}>
                        <YouTube className="youtubevid" videoId={id} />
                    </div>
                </div>

            )
        },
        vimeo: ({value}) => {
            const { url } = value
            const getRandomRotation = () => {
                return Math.random() * 2 - 1;

            }
            return (
                <div className="">
                    <div className={`relative rounded-[20px] border-[30px] mx-auto w-fit h-auto`} style={{transform: 'rotate(' + getRandomRotation() + 'deg)'}}>
                        <Vimeo className={""}
                            video={url}
                            playsInline={true}
                        />
                    </div>
                </div>

            )
        },
        quote: ({value}) => {
            const text = value.text;
            const getRandomRotation = () => {
                return Math.random() * 2 - 1;

            }
            return <div className={"is-animated origin-center text-center py-5"} style={{transform: 'rotate(' + getRandomRotation() + 'deg)'}}>
                <div className="text-2xl max-w-md mx-auto">{text}</div>
            </div>
        }
    }
};

function Text({ value }) {
    return <PortableText value={value} components={textComponents} />;
}

export default Text;
